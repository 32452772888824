<template>
    <registry-table
      ref="table"
      v-loading="!getRegistryRecordId() && !isEditor() && !isXrefReady"
      :element-loading-text="$locale.registry.not_available"
      element-loading-spinner="el"
      element-loading-background="rgb(244, 244, 245, 0.8)"
      v-show="!isHidden"
      @edit-record="editRecord"
      @add-record="addRecord"
      @query-search="querySearch"
      @grid-ready="isGridReady = true"
      @loaded="setRowCount"
      @column-state-change="columnChange"
      :CSS="CSS"
      :CSSClasses="CSSClasses"
      :readonly="_isReadonly"
      :key="key"
      :registry-id="registryId"
      :custom-columns="columns"
      :external-filters="dataFilters"
      :filtersForFilterCmp="filters"
      :loading="loading"
      :sideBar="sideBar"
      :closeToolPanel="closeToolPanel"
      :floatingFilter="floatingFilter"
      :row-double-clicked="rowDoubleClicked"
      :check-box-selection="checkBoxSelection"
      :is-pivot-mode="isPivotMode"
      :cssQuickSearch="cssQuickSearch"
      :show-button-ag-grid="visibleGridButtons"
      :placeholderQuickSearch="placeholderQuickSearch"
      :groupUseEntireRow="groupUseEntireRow"
      :show-count="showCount"
      :disabled-column-header="disabledColumnHeader"
      :hide-header="hideHeader"
      :wrap-header="wrapHeader"
      :state="editorRemote"
      :optimize-options="optimizeOptions.value"
      :theme="theme"
      :is-fast-card="isFastCard"
      :hide-export="hideExport"
      :rowClassRules="rowClassRules"
      :page-size="pageSize"
      :cache-block-size="cacheBlockSize"
      :outerXref="outerXref"
      :table-add-existing="tableAddExisting"
      :rowHeight="rowHeight"
    >
      <slot>
        <div>
          <el-button v-show="isEditor()" :type="isSaveFilter ? 'info' : 'warning'" size="small"
                     style="position: absolute; bottom: 10px; right: 350px;z-index: 2;" @click="saveFilterModel">{{$locale.main.button.save_filter}}</el-button>
        </div>
      </slot>

    </registry-table>
</template>

<script>
import mixin from '@/components/InterfaceEditor/components/mixins'
import VisibleMixin from '@/components/InterfaceEditor/components/visible_properties_mixin'
import RegistryTable from '@/services/RegistryTable/index.vue'
import RegistryCard from '@/components/RegistryCard/index.vue'
import FilterBuilder, { EComponentTypes } from '../utils'

export default {
  name: 'xref_outer_field_new',
  inject: {
    getRegistryRecordId: { default: () => () => null },
    isEditor: { default: () => false },
    openRegistryCard: { default: () => {} },
    forceUpdateSettingsPanel: { default: () => () => {} }
  },
  mixins: [mixin, VisibleMixin],
  components: { RegistryTable, RegistryCard },
  props: {
    tableAddExisting: {
      type: Object,
      default: () => ({}),
      editor: 'TableAddExisting'
    },
    registry_properties: {
      type: Array,
      frozen: true
    },
    filterModel: {
      type: [Object, Array],
      frozen: true
    },
    name: {
      type: String,
      description: 'Атрибут',
      options: {
        removeSpaces: true
      }
    },
    editorAlias: {
      type: String,
      description: 'Псевдоним'
    },
    registryId: {
      type: Number,
      description: 'Выберите реестр',
      editor: 'RegistrySelect',
      hidden: true
    },
    // столбцы от редактора столбцов
    columns: {
      type: Array,
      description: 'Столбцы',
      default: () => [],
      editor: 'TableColumns',
      options: {
        sourceType: 'registry'
      }
    },
    optimizeOptions: {
      type: Object,
      default: () => ({}),
      editor: 'OptimizeColumnsLoad'
    },
    sideBar: {
      type: Boolean,
      description: 'Скрыть боковую панель'
    },
    closeToolPanel: {
      type: Boolean,
      description: 'Свернуть боковую панель',
      default: true
    },
    checkBoxSelection: {
      type: Boolean,
      description: 'Чек-бокс выделение',
      default: false
    },
    floatingFilter: {
      type: Boolean,
      description: 'Фильтры столбцов'
    },
    rowDoubleClicked: {
      type: Boolean,
      description: 'Открытие двойным кликом'
    },
    groupUseEntireRow: {
      type: Boolean,
      description: 'Группировать по всей строке'
    },
    showCount: {
      type: Boolean,
      description: 'Не загружать количество записей'
    },
    disabledColumnHeader: {
      type: Boolean,
      default: false,
      description: 'Заблокировать шапку'
    },
    hideHeader: {
      type: Boolean,
      default: false,
      description: 'Скрыть шапку'
    },
    wrapHeader: {
      type: Boolean,
      default: false,
      description: 'Перенос текста в шапке'
    },
    hideExport: {
      type: Boolean,
      description: 'Скрыть экспорт в контекстном меню',
      default: false
    },
    alwaysActive: {
      type: Boolean,
      description: 'Всегда активно'
    },
    rowClassRules: {
      type: Array,
      default: function () {
        return []
      },
      editor: 'RowClassRules'
    },
    pageSize: {
      type: Number,
      description: 'Кол-во записей на странице',
      default: 100,
      options: {
        tooltip: {
          show: true,
          content: `По умолчанию 100`
        }
      }
    },
    cacheBlockSize: {
      type: Number,
      description: 'Кол-во загружаемых записей из БД',
      default: 50,
      options: {
        tooltip: {
          show: true,
          content: `ОСТОРОЖНО! Чем выше число, тем ниже скорость загрузки.<br>
          Слишком маленькое значение может спровоцировать повторные загрузки таблицы.<br>
          Если число меньше кол-ва записей помещаемой на странице - то будет повторяться загрузка до кол-ва записей на странице. По умолчанию 50`
        }
      }
    },
    rowHeight: {
      type: Number,
      description: 'Высота строк',
      options: {
        tooltip: {
          show: true,
          content: `Изменение высоты всех строк. В том числе группировочных. Пример: 50`
        }
      }
    },
    showButtonAgGrid: {
      type: Object,
      frozen: true
    },
    visibleToolbarButtons: {
      type: Object,
      default: function () {
        return {
          update: {
            isHidden: {
              type: 'never',
              condition_type: 'and',
              conditions: []
            }
          },
          add: {
            isHidden: {
              type: 'never',
              condition_type: 'and',
              conditions: []
            }
          },
          edit: {
            isHidden: {
              type: 'never',
              condition_type: 'and',
              conditions: []
            }
          },
          delete: {
            isHidden: {
              type: 'never',
              condition_type: 'and',
              conditions: []
            }
          },
          search: {
            isHidden: {
              type: 'never',
              condition_type: 'and',
              conditions: []
            }
          },
          export: {
            isHidden: {
              type: 'never',
              condition_type: 'and',
              conditions: []
            }
          },
          clearFilters: {
            isHidden: {
              type: 'never',
              condition_type: 'and',
              conditions: []
            }
          }
        }
      },
      editor: 'ShowButtonAgGrid'
    },
    cssQuickSearch: {
      type: String,
      description: 'CSS поиска',
      default: 'width: 30%; margin-left:50%;'
    },
    placeholderQuickSearch: {
      type: String,
      description: 'Плейсхолдер поиска',
      default: 'Поиск'
    },
    searchAttributes: {
      type: String,
      description: 'Поиск по',
      options: {
        tooltip: {
          show: true,
          content: 'Например attr_N_, attr_N_'
        }
      }
    },
    searchOrByAnd: {
      editor: 'Select',
      description: 'Связка в поиске',
      default: 'and',
      options: {
        tooltip: {
          show: true,
          content: `Связка И: Ищется точное совпадение. Например "Акцент 2". Найдет "Акцент 2" <br>
                    Связка ИЛИ: Ищется фраза. Например "Акцент 2". Найдет "Акцент-платформа 2.0"`
        },
        multiple: false,
        options: [
          { name: 'И', id: 'and' },
          { name: 'ИЛИ', id: 'or' }
        ]
      }
    },
    theme: {
      editor: 'Select',
      description: 'Стили таблицы',
      default: 'ag-theme-alpine',
      options: {
        multiple: false,
        options: [
          { name: 'Цвет: светлый; Высота: с отступами', id: 'ag-theme-alpine' },
          { name: 'Цвет: светлый; Высота: минимальная', id: 'ag-theme-balham' },
          { name: 'Цвет: темный; Высота: с отступами', id: 'ag-theme-alpine-dark' },
          { name: 'Цвет: темный; Высота: минимальная', id: 'ag-theme-balham-dark' }
        ]
      }
    },
    editorRemote: {
      type: Object,
      editor: 'RemoteList',
      description: 'Состояние',
      options: {
        multiple: false,
        clearable: true
      },
      default: () => {
        return {
          stateId: null
        }
      }
    },
    defaultCardId: {
      type: [Object, Number],
      description: 'Карточка',
      editor: 'Cards',
      default: () => {
        return {
          cardId: null,
          isWindow: false,
          windowTitle: '',
          windowWidth: '25'
        }
      }
    },
    filters: {
      type: Array,
      editor: 'Filters',
      options: {
        showXrefOption: true,
        showEqualsTypes: true
      },
      default () {
        return []
      }
    },
    defaults: {
      type: Array,
      default: () => {
        return []
      },
      editor: 'Filters',
      options: {
        title: 'По умолчанию',
        showXrefOption: false,
        showEqualsTypes: false
      }
    },
    initialColumnState: {
      type: Array,
      frozen: true
    },
    isPivotMode: {
      type: Boolean,
      frozen: true
    },
    // из configurator открытие модального окна, чтобы сохранить вид таблицы (columnState)
    openModalWindowByConfigurator: {
      type: Boolean,
      frozen: true
    },
    isFastCard: {
      type: Boolean,
      description: 'Карточка быстрого добавления',
      default: () => {
        return false
      }
    }
  },
  data () {
    return {
      id: null,
      outerXrefId: null,
      card: null,
      multi: false,
      rendered: false,
      observer: undefined,

      loading: false,
      isGridReady: false,
      isComponentReady: false,
      isSaveFilter: false,
      columnFilter: null,
      searchModel: null,
      saveStateColumn: null,
      isXrefReady: false
    }
  },
  provide () {
    return {
      xrefOuterFieldScope: this
    }
  },
  computed: {
    visibleGridButtons () {
      let res = {}
      for (const el in this.visibleToolbarButtons) {
        res[el] = this.checkConditions(this.visibleToolbarButtons[el].isHidden)
      }
      return res
    },
    dataFilters () {
      const builder = new FilterBuilder(
        this.filters,
        this.getModel(),
        this.$store,
        EComponentTypes.registry
      )

      const filters = builder.buildAsApiQl()

      if (this.outerXref.id && !this.outerXref.value) {
        filters.push({
          'eq': {
            [`attr_${this.outerXref.id}_id`]: '0' }
        })
      }

      if (this.isXrefReady) {
        let outerXrefFilter = {
          'equals_any': {
            [`attr_${this.outerXref.id}_id`]: this.outerXref.value.toString()
          }
        }
        filters.push(outerXrefFilter)
      }

      if (this.searchModel && this.searchAttributes) {
        // Проверка настройки поиска - "и/или"
        // replace(/\s+/g, '%') - заменить пробел на %
        let isParameterAnd = this.searchOrByAnd === 'and'
        let getSearchModel = isParameterAnd ? this.searchModel.trim() : this.searchModel.trim().replace(/\s+/g, '%')
        const orX = this.searchAttributes.split(',').reduce((acc, item) => {
          acc.or.push({ like: { [item.trim()]: `%${getSearchModel}%` } })

          return acc
        }, { or: [] })

        filters.push(orX)
      }

      return filters
    },
    key () {
      return `registry_${this.registryId}`
    },
    columnState () {
      if (this.isGridReady) {
        // TODO refactor
        // return this.$refs.table.getEventBus().$emit('getColumnState')
        return this.$refs.table.$refs.tableBody.$refs.grid?.columnApi?.getColumnState() || []
      }

      return []
    },
    pivotMode () {
      if (this.isGridReady) {
        // TODO refactor
        // return this.$refs.table.getEventBus().$emit('isPivotMode')
        return this.$refs.table.$refs.tableBody.$refs.grid.columnApi.isPivotMode()
      }

      return false
    },
    getFilterModel () {
      if (this.isGridReady) {
        return this.$refs.table.$refs.tableBody.$refs.grid.getFilterModel()
      }

      return {}
    },
    outerXref () {
      return {
        id: this.outerXrefId,
        value: this.getRegistryRecordId(),
        isMulti: this.multi,
        is: true
      }
    }
  },
  watch: {
    outerXref: {
      handler (value) {
        if (value.value) this.isXrefReady = true
      },
      deep: true
    },
    key () {
      this.isGridReady = false
      this.isComponentReady = false
      this.$emit('change-property', { name: 'initialColumnState', value: [] })
    },
    isGridReady (value) {
      // console.log('%c%s', 'color: skyblue;', 'Registry isGridReady', value)
      if (value && this.initialColumnState) {
        // Внешнее состояние таблицы
        this.$refs.table.$refs.tableBody.$refs.grid.columnApi.applyColumnState({ state: this.initialColumnState, applyOrder: true })
        this.isComponentReady = true
      } else if (value && (this.initialColumnState || []).length === 0) {
        this.isComponentReady = true
      }
      if (this.filterModel && Object.keys(this.filterModel).length) {
        this.$refs.table.$refs.tableBody.$refs.grid.gridApi.setFilterModel(this.filterModel)
      }
      if (this.isComponentReady && this.rowClassRules?.length) {
        setTimeout(() => {
          console.log('redrawRows')
          this.$refs.table.$refs.tableBody.$refs.grid.gridApi.redrawRows()
        }, 0)
      }
    },
    columnState (value) {
      if (this.isEditor() && this.isComponentReady) {
        this.$emit('change-property', { name: 'initialColumnState', value })
      }
    },
    pivotMode (value) {
      if (this.isEditor() && this.isComponentReady) {
        this.$emit('change-property', { name: 'isPivotMode', value })
      }
    },
    columnFilter (value) {
      if (this.isEditor() && this.isComponentReady) {
        this.$emit('change-property', { name: 'filterModel', value })
      }
    },
    columns () {
      this.addOptimizeAttribute()
    },
    optimizeOptions: {
      deep: true,
      handler () {
        this.addOptimizeAttribute()
      }
    },
    editorAlias () {
      this.forceUpdateSettingsPanel()
    }
  },
  mounted () {
    for (const el in this.showButtonAgGrid) {
      if (this.showButtonAgGrid[el] === true) {
        this.$set(this.visibleToolbarButtons[el], 'isHidden', { type: 'always', condition_type: 'and', conditions: [] })
        this.$set(this.showButtonAgGrid, el, false)
      }
    }

    let xref = this.registry_properties.find((item) => item.id === 'xref')
    if (!xref || !xref.value) {
      return false
    }
    let me = this
    this.$http.get(`${this.$config.api}/objecteditor/entities/${xref.value}`)
      .then((response) => {
        let xrefObjectId = response.data.data.object_id
        if (xrefObjectId) {
          me.id = xrefObjectId
          me.outerXrefId = xref.value
          this.$emit('change-property', { name: 'registryId', value: xrefObjectId })
        }
      })
  },
  methods: {
    setRowCount (value) {
      let rowCount = value.api.getDisplayedRowCount()
      this.$set(this.getModel(), this.name, rowCount === 0 ? null : rowCount)
    },
    // изменено состояие (вид) таблицы - сохранить его в пропсе initialColumnState
    columnChange (value) {
      let { columnState, savedPivotMode } = value
      this.$emit('change-property', { name: 'isPivotMode', value: savedPivotMode })
      this.$emit('change-property', { name: 'initialColumnState', value: columnState })
    },
    /**
     * Вернёт фильтры в формате ApiQL
     *
     * @return {*[]|*}
     */
    getPayload () {
      if (typeof this.$refs.table.getPayload !== 'undefined') {
        return this.$refs.table.getPayload()
      }

      return null
    },
    /**
     * Вернёт фильтры, которые есть в настройках таблицы
     *
     * @return {Array.<Object>}
     */
    getFilterProps () {
      return this.filters
    },
    /**
     * Вернёт выделенные строки в таблце
     *
     * @return {Array.<Object>}
     */
    getSelectedRows () {
      if (typeof this.$refs.table.$refs.tableBody.$refs.grid !== 'undefined') {
        return this.$refs.table.$refs.tableBody.$refs.grid.gridApi.getSelectedRows()
      }
      return []
    },
    addOptimizeAttribute () {
      if (this.optimizeOptions.showOptimizeOptions === true) {
        let optimizeAttrs = ''
        if (typeof this.columns !== 'undefined') {
          this.columns.forEach((item) => {
            switch (item.columnType) {
              case 'htmlField':
                if (item.hasOwnProperty('htmlTemplate')) {
                  optimizeAttrs += item.htmlTemplate.match(/attr_[0-9]+_/gi) + ','
                }
                break
              case 'fieldGroup':
                item.children.forEach((children) => {
                  optimizeAttrs += this.fieldGroupAttrsParse(children)
                })
                break
              default:
                if (item.field.match(/attr_[0-9]+_/gi) !== null) {
                  optimizeAttrs += item.field + ','
                }
            }

            if (item.clickType === 'open_card') {
              let cardAttr = item.card.constantRecordId ? item.card.constantRecordId : item.card.fieldId
              optimizeAttrs += `attr_${cardAttr}_,`
            }
          })
          this.$set(this.optimizeOptions, 'value', [...new Set(optimizeAttrs.slice(0, -1).split(','))].toString())
        }
      } else {
        this.$set(this.optimizeOptions, 'value', null)
      }
    },
    fieldGroupAttrsParse (item) {
      let optimizeAttrs = ''
      if (item.columnType === 'fieldGroup') {
        item.children.forEach((children) => {
          optimizeAttrs += this.fieldGroupAttrsParse(children)
        })
      } else {
        optimizeAttrs += item.field + ','
      }

      return optimizeAttrs
    },
    querySearch (value) {
      this.searchModel = value
    },
    saveFilterModel () {
      this.isSaveFilter = !this.isSaveFilter
      // TODO refactor
      // this.$refs.table.getEventBus().$emit('getFilterModel')
      this.columnFilter = this.$refs.table.$refs.tableBody.$refs.grid.getFilterModel()
    },
    async getCardId (recordId = null) {
      let url = `${this.$config.api}/registryservice/registry/${this.registryId}/card`
      if (recordId) {
        url = `${this.$config.api}/registryservice/registry/${this.registryId}/records/${recordId}/card`
      }
      let data = await this.$http.get(url)
      return data.data[0]
    },
    async openCard (recordId = null) {
      let card = {}
      if (this.defaultCardId.cardId) {
        card = {
          id: this.defaultCardId.cardId
        }
      } else {
        card = await this.getCardId(recordId)
      }
      if (card) {
        let initialData = {}
        if (!recordId) initialData = this.initialDataForCard()
        if (this.getRegistryId() !== this.id) {
          initialData = JSON.parse(JSON.stringify(this.getModel()))
        }
        // getRegistryRecordId - получить id реестра в котором находимся
        // outerXrefId - id ссылки на которую ссылается вн. ссылка
        initialData[`attr_${this.outerXrefId}_`] = this.getRegistryRecordId()
        let defaults = this.getDefaultsForCard()
        defaults.forEach((item) => {
          // Если поле мн. ссылка - из мн. ссылки взять первое значение
          if (Array.isArray(item.value)) {
            initialData[item.key] = item.value[0]
          } else {
            initialData[item.key] = item.value
          }
        })
        if (this.outerXref && this.outerXref.id && this.outerXref.value) {
          initialData[`attr_${this.outerXref.id}_`] = this.outerXref.value
        }
        this.openRegistryCard({
          registryId: this.registryId,
          cardId: card.id,
          cardName: card.name || '',
          recordId: recordId,
          initialData: initialData,
          registry: this.$refs.table
        })
      }
    },
    getDefaultsForCard () {
      let defaults = []
      if (this.defaults) {
        this.defaults.forEach((item) => {
          if (!item.type || item.type === 'field') {
            if (this.getModel()[item.attribute] && item.alias) {
              defaults.push({
                key: item.alias,
                value: this.getModel()[item.attribute]
              })
            }
          } else if (item.type === 'constant' && item.alias) {
            defaults.push({
              key: item.alias,
              value: item.attribute
            })
          } else if (item.type === 'current_user') {
            defaults.push({
              key: item.alias,
              value: this.$store.getters['Authorization/userId']
            })
          }
        })
      }
      return defaults
    },
    async editRecord (selectedRows) {
      let initialData = {}
      if (parseInt(this.getRegistryId()) !== parseInt(this.id)) {
        initialData = JSON.parse(JSON.stringify(this.getModel()))
      }
      this.loading = true
      console.log('selectedRows', selectedRows)
      if (selectedRows?.id) {
        await this.openCard(selectedRows.id)
      }
      this.loading = false
    },
    initialDataForCard () {
      let initialData = {}
      let defaults = this.getDefaultsForCard()
      defaults.forEach((item) => {
        // Если поле мн. ссылка - из мн. ссылки взять первое значение
        if (Array.isArray(item.value)) {
          initialData[item.key] = item.value[0]
        } else {
          initialData[item.key] = item.value
        }
      })
      return initialData
    },
    addRecord () {
      let card = {}
      if (this.defaultCardId.cardId) {
        card = {
          id: this.defaultCardId.cardId
        }
      } else {
        this.openCard()
        return
      }
      let initialData = this.initialDataForCard()

      if (this.isFastCard) {
        this.openQuickAddCardByCheckbox(null, initialData)
      } else {
        if (this.defaultCardId.isWindow) {
          const h = this.$createElement
          let customClass = 'custom_scrollbar '
          if (this.defaultCardId.windowWidth) {
            customClass += `dashboard_window_width_${this.defaultCardId.windowWidth}`
          }
          let me = this
          this.$msgbox({
            title: me.defaultCardId.windowTitle,
            customClass: customClass,
            message: h('registry-card', { style: {
              height: me.defaultCardId.windowHeight || ''
            },
            props: {
              cardId: card.id,
              registryId: me.registryId,
              recordId: null,
              initialData: initialData
            },
            on: {
              cancelChanges: function () {
                me.$msgbox.close()
              },
              recordAdded: function () {
                me.$refs.table.addRecord()
              }
            },
            key: this.generateGuid()
            }),
            showCancelButton: false,
            showConfirmButton: false,
            closeOnClickModal: false
          })
        } else {
          this.openRegistryCard({
            registryId: this.registryId,
            cardId: card.id,
            cardName: card.name,
            recordId: null,
            initialData: initialData,
            registry: {
              addRecord: () => {
                this.$refs.table.addRecord()
              },
              updateRecord: () => {}
            }
          })
        }
      }
    },
    openQuickAddCardByCheckbox (quickAddCard, initialData = {}) {
      const h = this.$createElement
      let customClass = 'custom_scrollbar '
      if (this.defaultCardId.windowWidth) {
        customClass += `window_width_${this.defaultCardId.windowWidth}`
      }
      const me = this
      this.$msgbox({
        customClass: customClass,
        message: h('registry-card', {
          props: {
            cardId: this.defaultCardId.cardId,
            registryId: me.registryId,
            parentContext: null,
            model: {},
            quick: true,
            initialData: initialData
          },
          on: {
            'quick-add': async data => {
              const cardFast = await me.getFastCard(data, me.registryId)
              me.openRegistryCard({
                registryId: me.registryId,
                cardId: cardFast.id,
                cardName: cardFast.name,
                recordId: null,
                initialData: data,
                registry: {
                  addRecord: () => {
                    this.$refs.table.addRecord()
                  },
                  updateRecord () {}
                }
              })
              me.$msgbox.close()
            },
            cancelChanges () {
              me.$msgbox.close()
            }
          },
          key: me.generateGuid()
        }),
        showCancelButton: false,
        showConfirmButton: false,
        closeOnClickModal: false
      })
    },
    async getFastCard (recordData = null, registryId) {
      let data = await this.$http.post(`${this.$config.api}/registryservice/registry/${registryId}/card`,
        recordData, { hideNotification: true })
      return data.data[0]
    },
    getRegistryId () {
      return this.registryId
    },
    checkConditions (data, sourceRegistry = false, fields = {}) {
      if (!data || data.type === 'never') {
        return false
      } else if (data.type === 'always') {
        return true
      }
      let result = false
      data.conditions.forEach((condition) => {
        if (!condition.attribute || !condition.type) {
          return false
        }
        let conditionResult = false
        let attribute
        if (sourceRegistry) {
          attribute = fields[`attr_${condition.attribute}_`]
        } else {
          attribute = this.getModel()[condition.attribute]
        }
        try {
          attribute = JSON.parse(attribute)
        } catch (e) {

        }
        if (attribute instanceof Array) {
          attribute = attribute.map(item => item.id || item).join('|||')
        }
        let value = condition.value
        if (value === 'user_id') {
          value = this.$store.getters['Authorization/userId']
        }
        if (value === 'role_id') {
          value = this.$store.getters['Authorization/roleId']
        }
        switch (condition.type) {
          case 'contains_in_array':
            let attrArray = (attribute || '').toString().split('|||')
            conditionResult = attrArray.includes(value.toString())
            break

          case 'not_contains_in_array':
            let _attrArray = (attribute || '').toString().split('|||')
            conditionResult = !_attrArray.includes(value.toString())
            break
          case 'contains':
            const _attr = (attribute || '').toString().split('|||')
            _attr.forEach((item) => {
              if (item.indexOf(value) !== -1) {
                conditionResult = true
              }
            })
            break
          case 'equals':
            if (attribute == value) {
              conditionResult = true
            }
            break
          case 'not_equals':
            if (attribute != value) {
              conditionResult = true
            }
            break
          case 'is_null':
            if (
              attribute === null ||
              typeof attribute === 'undefined' ||
              attribute === ''
            ) {
              conditionResult = true
            }
            break
          case 'is_not_null':
            if (
              attribute !== null &&
              typeof attribute !== 'undefined' &&
              attribute !== ''
            ) {
              conditionResult = true
            }
            break
          default:
            break
        }
        condition.result = conditionResult
      })

      if (data.condition_type === 'and') {
        if (data.conditions.length === data.conditions.filter(item => item.result).length) {
          result = true
        }
      } else if (data.condition_type === 'or') {
        if (data.conditions.find(item => item.result)) {
          result = true
        }
      }

      return result
    }
  }
}
</script>

<style scoped>

</style>
